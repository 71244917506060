<template>
  <div
    class="OfferSlider overflow-hidden lg:overflow-visible relative"
    :class="{
      'OfferSlider--left-layout': orientation === 'left',
      'OfferSlider--right-layout': orientation === 'right',
    }"
  >
    <Swiper
      :modules="[SwiperNavigation]"
      :breakpoints="{
        '300': {
          slidesPerView: items.length === 1 ? 1 : 1.4,
          spaceBetween: 20,
          ...mobileOptions,
        },
        '768': {
          slidesPerView: items.length === 1 ? 1 : Number(desktopSlides) | 'auto',
          spaceBetween: 30,
          ...desktopOptions,
        },
      }"
      :autoplay="autoPlay ? { delay: autoPlay } : false"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="h-full"
    >
      <SwiperSlide v-for="(item, idx) in items" :key="idx" class="swiper-slide">
        <slot :item="{ ...item, idx }" />
      </SwiperSlide>
      <template v-if="centered">
        <SwiperSlide v-for="(item, idx) in items" :key="idx" class="swiper-slide">
          <slot :item="{ ...item, idx }" />
        </SwiperSlide>
      </template>
      <SwiperControls :current-index="currentIndex" @change="index => (currentIndex = index)" />
    </Swiper>

    <div v-if="arrows" class="SliderNav">
      <div class="hidden md:flex items-center gap-x-2">
        <span class="text-primary-1-100 text-sm font-normal whitespace-nowrap">
          0{{ currentIndex + 1 }} / 0{{ items.length }}</span
        >
        <div class="flex gap-x-15">
          <button
            class="w-12 h-12 outline-none [ flex items-center justify-center ]"
            aria-label="previous"
            @click="prevSlide"
          >
            <svg-icon-chevron-left
              class="text-primary-1-100 stroke-current"
              :class="{
                'transform scale-x-reverse': $i18n.locale === 'ar',
              }"
              width="10"
              height="20"
            />
          </button>

          <button
            class="w-12 h-12 outline-none [ flex items-center justify-center ]"
            aria-label="next"
            @click="nextSlide"
          >
            <svg-icon-chevron-right
              class="text-primary-1-100 stroke-current"
              :class="{
                'transform scale-x-reverse': $i18n.locale === 'ar',
              }"
              width="10"
              height="20"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { OffersContent } from '@robustastudio/e-commerce/dist/runtime/composables/home';
import { OfferCardFragment } from '~/graphql/fragments';

import { PropType } from 'vue';
import { Unpacked } from '~/types/utils';

type Offer = Array<Unpacked<OffersContent> & OfferCardFragment>;

const props = defineProps({
  items: {
    type: Array as PropType<Offer>,
    required: true,
  },
  type: {
    type: String,
    default: 'primary',
  },
  autoPlay: {
    type: Number,
    default: 0,
  },
  desktopSlides: {
    type: [Number, String],
    default: 2,
  },
  desktopOptions: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  mobileOptions: { type: Object, default: () => ({}) },
  arrows: {
    type: Boolean,
    default: false,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 1,
  },
  orientation: {
    type: String,
    default: 'normal',
  },
});

const currentIndex = ref(0);

function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + 1);
}

function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - 1);
}

function getValidValue(num: number) {
  return clamp(0, props.items.length - 1, num);
}
</script>

<style lang="postcss" scoped>
ul {
  .nuxt-link-exact-active {
    @apply relative font-bold text-primary-700;
    &::after {
      @apply bg-secondary-400 absolute;
      content: '';
      height: 9px;
      width: calc(100% + 0.5rem);
      bottom: -4px;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
}

.category-scroll-list {
  @apply px-5;
  /* 40px is the padding on either side */
  width: calc(100vw);
}

.-scale-x-100 {
  transform: scaleX(-1);
}

.swiper-nav {
  @apply hidden lg:flex;
  width: 40px;
  height: 40px;
  &::after {
    content: unset;
  }
  svg {
    @apply text-black;
    height: 26px;
  }

  &__inverted {
    @apply border-white;

    svg {
      @apply text-white;
    }
  }

  &.swiper-button-next {
    transform: scaleX(-1);
    right: 10px;
  }

  &.swiper-button-prev {
    left: 10px;
  }

  &.swiper-button-disabled {
    opacity: 0.3;

    svg {
      @apply text-gray-200;
    }
  }
}

.swiper-slide {
  @apply flex flex-shrink-0 items-center justify-center;
}

.swiper-slide-prev,
.swiper-slide-next {
  :deep(.OfferCard__cta--button) {
    display: none;
  }
  :deep(.OfferCard__cta--link) {
    display: block;
  }
}

.SliderNav {
  @apply z-10;
  position: absolute;
  top: 500px;
}

/* Left and Right Orientation */
.OfferSlider {
  &--left-layout {
    .swiper-slide-prev {
      @screen md {
        @apply justify-start;
      }
    }

    .swiper-slide-next {
      @screen md {
        @apply mt-40 justify-end;
      }
    }

    .SliderNav {
      left: 0;
    }
  }
  &--right-layout {
    .swiper-slide-prev {
      @screen md {
        @apply mt-40 justify-start;
      }
    }

    .swiper-slide-next {
      @screen md {
        @apply justify-end;
      }
    }
    .SliderNav {
      right: 0;
    }
  }
}

@media (max-width: 767px) {
  .swiper-slide:not(.swiper-slide-active) {
    @apply mt-5;

    :deep(.OfferCard) {
      &__title,
      &__description,
      &__cta {
        opacity: 0;
      }
    }
  }
}
</style>
